.navvv h1 {
  margin-top: 100px;
  text-align: center;
  font-size: 60px;
  line-height: 70px;
  font-family: "roboto", sans-serif;
}

.navvv #container {
  margin: 0 auto;
  max-width: 890px;
}

.navvv p {
  text-align: center;
}

.navvv .toggle,
[id^="drop"] {
  display: none;
}

.navvv {
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 8px 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  margin-bottom: 200px;
}

.navvv #logo {
  display: block;
  padding: 0 30px;
  font-size: 20px;
  line-height: 60px;
  color: #fff;
  text-decoration: none;
}

.navvv ul {
  list-style: none;
  display: flex;
  margin-right: 20px;
}

.navvv ul li {
  margin: 0px;
  color: #0b4f6c;
  font-size: 16px;
  text-align: center;
  font-family: omnesregular;
}

.navvv a {
  padding: 12px 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* line-height: 60px; */
  text-decoration: none;
  color: #0b4f6c;
  font-size: 16px;
  text-align: center;

  display: flex;
  align-items: center;
}
.navvv ul ul li a {
  padding-top: 0px;
  padding-bottom: 0px;
}
.navvv a:hover {
  /* text-decoration: underline; */
  text-decoration-color: #71b9d8;
  /* border-bottom: 2px solid #71b9d8; */
  text-decoration-thickness: 2px;

  text-decoration-skip-ink: auto;
  color: #71b9d8;
}
.navvv ul ul li a:hover {
  border-bottom: none;
}

.navvv a svg {
  margin-right: 10px;
}

/* .c-u {

          background-color: #66CEF5;
          padding: 8px;
          margin-top: -5px;
          border-radius: 24px;
          padding-left: 16px;
          padding-right: 16px;
          font-size: 14px;
      } */

.navvv ul ul {
  display: none;
  position: absolute;
  top: 58px;
  padding: 0;
  margin: 0;

  text-align: left;
  padding: 2px;
  background-color: white;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.navvv hr {
  color: #d7d7d7;
  background-color: lightgray;
  width: 90%;
  margin: 0 auto;
  border-color: #ede8e814;
}

.navvv ul li:hover > ul {
  display: inherit;
}

.navvv ul ul li {
  /* width: 170px; */
  float: none;
  display: list-item;
  position: relative;
  text-align: left;
  padding: 5px;
  padding-bottom: 16px;
  padding-top: 12px;
}

.navvv ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}

li > a:only-child:after {
  content: "";
}

/* Media Queries */
@media all and (max-width: 768px) {
  .navvv #logo {
    display: block;
    padding: 0;
    width: auto;
    text-align: left;
    float: none;
    line-height: 1;
  }

  .navvv {
    margin: 0;
    display: block;
  }

  .toggle + a,
  .menu {
    display: none;
  }

  .navvv .toggle {
    display: block;
    background-color: #254441;
    padding: 0 20px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    line-height: 60px;
    text-decoration: none;
    border: none;
  }

  [id^="drop"]:checked + ul {
    display: block;
  }

  .navvv ul {
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-start;
    /* Align items to the left */
    margin: 0;
  }

  .navvv ul li {
    display: block;
    width: 100%;
    margin: 0;
    background-color: #254441;
  }

  .navvv a {
    padding: 0 20px;
  }

  .navvv ul ul .toggle,
  .navvv ul ul a {
    padding: 0 20px;
  }

  .navvv ul ul ul a {
    padding: 0 20px;
  }

  .navvv ul li ul li .toggle,
  .navvv ul ul a {
    background-color: #212121;
  }

  .navvv ul ul {
    float: none;
    position: static;
    color: #ffffff;
  }

  .navvv ul ul li:hover > ul,
  .navvv ul li:hover > ul {
    display: none;
  }

  .navvv ul ul li {
    display: block;
    width: 100%;
  }

  .navvv ul ul ul li {
    position: static;
  }
}

@media all and (max-width: 330px) {
  .navvv ul li {
    display: block;
    width: 94%;
  }
}

body {
  margin: 0;
  padding: 0;
}

.navvv ul p {
  font-size: 14px;
  font-family: omnesregular;
  line-height: 120%;
  padding-top: 4px;
  margin: 0px;
  color: rgb(163, 163, 163);
  padding-left: 50px;
  text-align: left;
}
.cu {
  background-color: #2294c1;
  color: white;
  border-radius: 30px;
}
.cu a {
  color: white;
}

@media (max-width: 768px) {
  .navvv {
    display: none;
  }
}
